/* eslint-disable no-param-reassign, import/no-cycle */
// This project is setup using redux-toolkit which includes immer by default.
// Hence state is mutable.

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk, RootState } from '../../store';
import { hitData, queryLogData } from './initialState';
import ReviewState, {
  CheckData,
  CountData,
  Hit,
  ApplicationImages,
  InputData,
  QueryLog,
} from './interface';
// import Sample1 from '../../../sample/sample1';

const initialState: ReviewState = {
  queryLog: queryLogData,
  currentHit: hitData,
  currentHitCategory: 'mismatches',
  currentHitIndex: 0,
  urlRequestId: '',
  debug: false,
  fetchStatus: false,
  urlCategory: 'mismatches',
  urlHitIndex: 0,
  urlDebug: false,
  urlCrop: 'fraas',
  error: false,
  errorMessage: '',
  zoomSelfie: false,
  zoomIdFace: false,
  zoomIdDetails: false,
  screenShotError: false,
  screenShotDone: false,
  noHit: false,
};

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    setQueryLog: (state, action: PayloadAction<QueryLog>) => {
      state.queryLog = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setNoHit: (state, action: PayloadAction<QueryLog>) => {
      const totalHit =
        action.payload.count.total.matches +
        action.payload.count.total.mismatches;
      if (totalHit === 0) state.noHit = true;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setScreenShotError: (state, action: PayloadAction<boolean>) => {
      state.screenShotError = action.payload;
    },
    setScreenShotDone: (state, action: PayloadAction<boolean>) => {
      state.screenShotDone = action.payload;
    },
    setZoomSelfie: (state) => {
      const toggleValueSelfie = !state.zoomSelfie;
      state.zoomSelfie = toggleValueSelfie;
    },
    setZoomIdFace: (state) => {
      const toggleValueIdFace = !state.zoomIdFace;
      state.zoomIdFace = toggleValueIdFace;
    },
    setZoomIdDetails: (state) => {
      const toggleValueIdDetails = !state.zoomIdDetails;
      state.zoomIdDetails = toggleValueIdDetails;
    },
    setFetchStatus: (state, action: PayloadAction<boolean>) => {
      state.fetchStatus = action.payload;
    },
    setDebug: (state, action: PayloadAction<string | null>) => {
      let booleanValue;
      if (action.payload && action.payload === 'true') booleanValue = true;
      else booleanValue = false;
      if (state.fetchStatus) {
        state.debug = booleanValue;
      } else {
        state.urlDebug = booleanValue;
      }
    },
    setCurrentHitCategory: (
      state,
      action: PayloadAction<'matches' | 'mismatches'>
    ) => {
      if (state.fetchStatus) {
        const misMatchExist = state.queryLog.count.total.mismatches > 0;
        const matchExist = state.queryLog.count.total.matches > 0;
        let categoryValue = action.payload;
        if (!misMatchExist && matchExist && action.payload === 'mismatches')
          categoryValue = 'matches';
        else if (misMatchExist && !matchExist && action.payload === 'matches')
          categoryValue = 'mismatches';

        const totalHit = state.queryLog.count.total[categoryValue];
        if (totalHit) {
          state.currentHitCategory = categoryValue;
          state.currentHitIndex = 0;
          const hits = state.queryLog?.hits[state.currentHitCategory];
          if (hits) state.currentHit = hits[state.currentHitIndex];
        }
      } else {
        state.urlCategory = action.payload;
      }
    },
    setCurrentHitIndex: (state, action: PayloadAction<number>) => {
      if (state.fetchStatus) {
        const totalHit = state.queryLog.count.total[state.currentHitCategory];
        if (totalHit) {
          const hits = state.queryLog.hits[state.currentHitCategory];
          if (action.payload < 0 || action.payload >= totalHit)
            state.currentHitIndex = 0;
          else state.currentHitIndex = action.payload;
          if (hits) state.currentHit = hits[state.currentHitIndex];
        }
      } else {
        state.urlHitIndex = action.payload;
      }
    },
    setAfterFetch: (state) => {
      const misMatchExist = state.queryLog.count.total.mismatches > 0;
      const matchExist = state.queryLog.count.total.matches > 0;
      let categoryValue = state.urlCategory;
      if (!misMatchExist && matchExist && state.urlCategory === 'mismatches')
        categoryValue = 'matches';
      else if (misMatchExist && !matchExist && state.urlCategory === 'matches')
        categoryValue = 'mismatches';

      const totalHitCategory = state.queryLog.count.total[categoryValue];
      if (totalHitCategory) {
        state.currentHitCategory = categoryValue;
        const hits = state.queryLog?.hits[state.currentHitCategory];
        if (hits) state.currentHit = hits[state.urlHitIndex];
      } else state.currentHit = hitData;

      const totalHit = state.queryLog.count.total[categoryValue];
      if (totalHit) {
        const hits = state.queryLog.hits[categoryValue];
        if (state.urlHitIndex < 0 || state.urlHitIndex >= totalHit)
          state.currentHitIndex = 0;
        else state.currentHitIndex = state.urlHitIndex;
        if (hits) state.currentHit = hits[state.currentHitIndex];
      } else state.currentHitIndex = 0;

      state.debug = state.urlDebug;
    },
  },
});

export const {
  setCurrentHitCategory,
  setCurrentHitIndex,
  setQueryLog,
  setDebug,
  setNoHit,
  setFetchStatus,
  setAfterFetch,
  setError,
  setErrorMessage,
  setZoomIdDetails,
  setZoomIdFace,
  setZoomSelfie,
  setScreenShotDone,
  setScreenShotError,
} = reviewSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const getQueryLog = (requestId: string): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(setFetchStatus(false));
    const queryLogResult: any = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/v1/logs?requestId=${requestId}`,
      {
        headers: {
          authorization: localStorage.getItem('accessToken'),
        },
      }
    );
    dispatch(setQueryLog(queryLogResult.data));
    dispatch(setNoHit(queryLogResult.data));
    dispatch(setFetchStatus(true));
    dispatch(setError(false));
    dispatch(setAfterFetch());
  } catch (err) {
    dispatch(setError(true));
    if (err.response.status === 401) {
      dispatch(setErrorMessage('Unauthorized'));
    } else {
      dispatch(setErrorMessage('An Error Occured! Report RequestId'));
    }
  }

  // For loading local sample - testing purpose
  // console.log(requestId);
  // function timeout(ms: number) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }
  // async function sleep() {
  //   await timeout(1000);
  //   dispatch(setQueryLog(Sample1));
  //   dispatch(setFetchStatus(true));
  //   dispatch(setAfterFetch());
  // }
  // sleep();
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrentHit = (state: RootState): Hit =>
  state.review.currentHit;
export const selectCurrentHitIndex = (state: RootState): number =>
  state.review.currentHitIndex;
export const selectCurrentHitCategory = (
  state: RootState
): 'matches' | 'mismatches' => state.review.currentHitCategory;
export const selectRequestId = (state: RootState): string =>
  state.review.queryLog.requestId;
export const selectClientId = (state: RootState): string =>
  state.review.queryLog.clientId;
export const selectCheck = (state: RootState): CheckData =>
  state.review.queryLog.check;
export const selectCount = (state: RootState): CountData =>
  state.review.queryLog.count;
export const selectApplicationImages = (state: RootState): ApplicationImages =>
  state.review.queryLog.images;
export const selectInput = (state: RootState): InputData =>
  state.review.queryLog.input;
export const selectDebug = (state: RootState): boolean => state.review.debug;
export const selectHits = (state: RootState) => state.review.queryLog.hits;
export const selectFetchStatus = (state: RootState): boolean =>
  state.review.fetchStatus;
export const selectZoomSelfie = (state: RootState): boolean =>
  state.review.zoomSelfie;
export const selectZoomIdFace = (state: RootState): boolean =>
  state.review.zoomIdFace;
export const selectZoomIdDetails = (state: RootState) =>
  state.review.zoomIdDetails;
export const selectError = (state: RootState): boolean => state.review.error;
export const selectErrorMessage = (state: RootState): string =>
  state.review.errorMessage;
export const selectScreenShotError = (state: RootState): boolean =>
  state.review.screenShotError;
export const selectScreenShotDone = (state: RootState): boolean =>
  state.review.screenShotDone;
export const selectNoHit = (state: RootState): boolean => state.review.noHit;

export default reviewSlice.reducer;
