/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import './mismatchBar.css';

type Props = {
  decision: string;
  debug?: boolean;
  categoryId?: number;
  categoryAlias?: string;
  enrollDate?: string;
  clientId?: string;
  requestId?: string;
  appId?: string;
  noOfMismatch: number;
  noOfMatch: number;
  active: 'mismatches' | 'matches';
  currentActive: number;
  nextHit: (scrollFlag: boolean) => void;
  previousHit: (scrollFlag: boolean) => void;
  setHitCategory: (string: 'matches' | 'mismatches') => void;
};

const MismatchBar = ({
  decision,
  debug,
  categoryId,
  categoryAlias,
  clientId,
  requestId,
  enrollDate,
  appId,
  noOfMismatch,
  noOfMatch,
  active,
  currentActive,
  nextHit,
  previousHit,
  setHitCategory,
}: Props) => {
  let mismatchToggleActive = null;
  let matchToggleActive = null;
  let mismatchNumber = <span>({noOfMismatch})</span>;
  let matchNumber = <span>({noOfMatch})</span>;
  if (active === 'mismatches') {
    mismatchToggleActive = 'MismatchBar-Toggle-Active';
    mismatchNumber = (
      <span>
        ({currentActive}/{noOfMismatch})
      </span>
    );
  } else {
    matchToggleActive = 'MismatchBar-Toggle-Active';
    matchNumber = (
      <span>
        ({currentActive}/{noOfMatch})
      </span>
    );
  }
  return (
    <div className="MismatchBar">
      <div className="MismatchBar-Details-Holder">
        <p className="MismatchBar-Decision-Holder">
          Decision: <span className="MismatchBar-Decision">{decision}</span>
        </p>
        {debug ? (
          <>
            <p className="MismatchBar-Decision-Holder">
              CategoryId:{' '}
              <span className="MismatchBar-Decision">{categoryId}</span>
            </p>
            <p className="MismatchBar-Decision-Holder">
              CategoryAlias:{' '}
              <span className="MismatchBar-Decision">{categoryAlias}</span>
            </p>
            <p className="MismatchBar-Decision-Holder">
              AppId: <span className="MismatchBar-Decision">{appId}</span>
            </p>
            <p className="MismatchBar-Decision-Holder">
              EnrollDate:{' '}
              <span className="MismatchBar-Decision">{enrollDate}</span>
            </p>
            <p className="MismatchBar-Decision-Holder">
              ClientId: <span className="MismatchBar-Decision">{clientId}</span>
            </p>
            <p className="MismatchBar-Decision-Holder">
              RequestId:{' '}
              <a href={`/review/${requestId}`} className="MismatchBar-Decision">
                {requestId}
              </a>
            </p>
          </>
        ) : null}
      </div>
      <div className="MismatchBar-Toggle-Holder">
        <div className={`MismatchBar-Toggle ${matchToggleActive}`}>
          <div className="MismatchBar-Toggle-Row">
            <p
              onClick={() => setHitCategory('matches')}
              className="MismatchBar-Field"
            >
              Match: <span>{matchNumber}</span>
            </p>
          </div>
          {active === 'matches' ? (
            <div className="MismatchBar-Toggle-Row">
              <ArrowBack
                className="MismatchBar-ArrowBack"
                onClick={() => previousHit(false)}
              />
              <ArrowForward
                className="MismatchBar-ArrowForward"
                onClick={() => nextHit(false)}
              />
            </div>
          ) : null}
        </div>
        <div className={`MismatchBar-Toggle ${mismatchToggleActive}`}>
          <div className="MismatchBar-Toggle-Row">
            <p
              onClick={() => setHitCategory('mismatches')}
              className="MismatchBar-Field"
            >
              Mismatch: <span>{mismatchNumber}</span>
            </p>
          </div>
          {active === 'mismatches' ? (
            <div className="MismatchBar-Toggle-Row">
              <ArrowBack
                className="MismatchBar-ArrowBack"
                onClick={() => previousHit(false)}
              />
              <ArrowForward
                className="MismatchBar-ArrowForward"
                onClick={() => nextHit(false)}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MismatchBar;
