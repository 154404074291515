import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getAccessTokenValidity,
  selectAuthState,
  selectCheckMade,
} from '../../containers/loginPage/loginSlice';
import SearchBar from '../searchBar/index';
import Spinner from '../spinner/index';

const HomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getAccessTokenValidity(localStorage.getItem('accessToken') || ''));
  }, [dispatch]);

  const checkMade = useSelector(selectCheckMade);
  const isAuthenticated = useSelector(selectAuthState);
  if (checkMade && !isAuthenticated) {
    history.push('/login');
  }

  const logOutHandler = () => {
    localStorage.setItem('accessToken', '');
    history.push('/login');
  };

  const render = isAuthenticated ? (
    <div>
      <SearchBar logOut={logOutHandler} />
      <h1>Orion QC Portal</h1>
      <h5>Enter RequestId to continue</h5>
    </div>
  ) : (
    <Spinner />
  );
  return render;
};

export default HomePage;
