import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import ReviewPage from './app/containers/reviewPage/index';
import HomePage from './app/components/homePage/index';
import './App.css';
import LoginPage from './app/containers/loginPage';
import ClientReviewPage from './app/containers/clientReviewPage';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/review/:requestId" component={ReviewPage} />
          <Route path="/clientreview/:requestId" component={ClientReviewPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="*" component={HomePage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
