import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectRole } from '../../containers/loginPage/loginSlice';
import './searchBar.css';

type Props = {
  debug?: () => void;
  screenShot?: () => void;
  debugValue?: boolean;
  logOut: () => void;
};

const SearchBar = ({ debug, debugValue, screenShot, logOut }: Props) => {
  const [requestId, setRequestId] = useState();
  const history = useHistory();
  const role = useSelector(selectRole);

  const onChangeHandler = (e: any) => {
    setRequestId(e.target.value);
  };

  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    console.log(role);
    if (role === 'admin') history.push(`/review/${requestId}`);
    else history.push(`/clientreview/${requestId}`);
  };

  return (
    <div className="SearchBar">
      <form onSubmit={(e) => onSubmitHandler(e)}>
        <input
          className="SearchBar-Input"
          type="text"
          placeholder="Paste RequestId and Hit Enter"
          value={requestId}
          onFocus={(e) => {
            e.target.placeholder = '';
            return e;
          }}
          onBlur={(e) => {
            e.target.placeholder = 'Paste RequestId and Hit Enter';
            return e;
          }}
          onChange={onChangeHandler}
        />
      </form>

      <button
        className="SearchBar-Logout"
        type="button"
        onClick={() => logOut()}
      >
        Sign Out
      </button>

      {debug ? (
        <button
          className={`SearchBar-Debug ${
            debugValue ? 'SearchBar-Debug-On' : null
          }`}
          type="button"
          onClick={() => debug()}
        >
          Show Detailed View
        </button>
      ) : null}

      {screenShot ? (
        <button
          className="SearchBar-ScreenShot"
          onClick={() => screenShot()}
          type="button"
        >
          ScreenShot
        </button>
      ) : null}
    </div>
  );
};

export default SearchBar;
