/* eslint-disable import/no-cycle */

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reviewReducer from './containers/reviewPage/reviewSlice';
import loginReducer from './containers/loginPage/loginSlice';
import clientReviewReducer from './containers/clientReviewPage/clientReviewSlice';

export const store = configureStore({
  reducer: {
    review: reviewReducer,
    login: loginReducer,
    clientreview: clientReviewReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
